import { useState, useEffect } from 'react';

import { TestimonialsContainer, FadeOutDiv, TestimonialContainer, ContentContainer } from './styles';

import { LeftSideDoubleLeftSign, RightSideDoubleRightSign } from '@/Components/styles';

const BASEURL = process.env.FRONT_END_URL;

// images generated here: https://thispersondoesnotexist.com/
const TESTIMONIALS = [
    {
        image: `${BASEURL}/assets/testimonials/Peter7.jpg`,
        name: 'Peter H.',
        info: 'Gymnasium 7. Klasse',
        text: 'Habe es zum ersten Mal wirklich verstanden. Die Interaktion kommt sonst sehr häufig zu kurz.',
    },
    {
        image: `${BASEURL}/assets/testimonials/Valeska9.jpg`,
        name: 'Valeska ',
        info: 'Realschule 9. Klasse',
        text:
            'Die App vermittelt das Gefühl von Verstanden werden und auch Fragen zu dürfen. Die Interaktion ist super, da hat man das Gefühl endlich Mal ernst genommen zu werden.',
        position: 'center',
    },
    {
        image: `${BASEURL}/assets/testimonials/Tara11.jpg`,
        name: 'Tara L.',
        info: 'Gymnasium 11. Klasse',
        text: 'Also ich bin echt begeistert 👍👍 Es ist super erklärt. Macht bitte weiter so!',
    },
];

const Testimonial = ({ testimonial }) => {
    const { image, name, info, text, position } = testimonial;
    return (
        <TestimonialContainer position={position}>
            <ContentContainer position={position}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={image} alt={`testimonial_${name}`} />
                    <div style={{ flex: '5 1', paddingTop: '5px' }}>
                        <span>{name}</span>
                        <br />
                        <p>{info}</p>
                    </div>
                </div>
                <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                    <p>{text}</p>
                </div>
            </ContentContainer>
            {!testimonial.center && <div style={{ height: '15px', width: '100%' }} />}
        </TestimonialContainer>
    );
};

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState(TESTIMONIALS);

    const rotate = (direction = 'right') => {
        setTestimonials((currTestimonials) => {
            const midPoint = parseInt(currTestimonials.length / 2);
            let newTestimonials = [];
            if (direction === 'right') {
                newTestimonials = [...currTestimonials.slice(-1), ...currTestimonials.slice(0, -1)];
            } else {
                newTestimonials = [...currTestimonials.slice(1), currTestimonials[0]];
            }

            const centeredTestionalias = newTestimonials.map((testimonial, idx) => {
                if (idx === midPoint) {
                    testimonial.position = 'center';
                } else {
                    delete testimonial.position;
                }
                return testimonial;
            });
            return centeredTestionalias;
        });
    };

    useEffect(() => {
        let mounted = true;
        const autoRotate = setInterval(() => {
            if (mounted) {
                rotate('right');
            }
        }, 30000);
        return () => {
            clearInterval(autoRotate);
            mounted = false;
        };
    }, []);

    return (
        <TestimonialsContainer>
            <LeftSideDoubleLeftSign
                outerProps={{ onClick: () => rotate('left'), style: { zIndex: 100 } }}
                innerProps={{ color: '#E28215', scalar: 1.5, filter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4))' }}
            />
            <FadeOutDiv>
                {testimonials.map((_) => (
                    <Testimonial testimonial={_} key={_.name} />
                ))}
            </FadeOutDiv>
            <RightSideDoubleRightSign
                outerProps={{ onClick: () => rotate('right'), style: { zIndex: 100 } }}
                innerProps={{ color: '#E28215', scalar: 1.5, filter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4))' }}
            />
        </TestimonialsContainer>
    );
};

export default Testimonials