import Head from 'next/head';
import Landing from '@/Components/Landing';

const LandingPage = () => {
    return (
        <>
            <Head>
                <title>So macht Mathe Spaß - Mit künstlicher Intelligenz zum Lernerfolg</title>
                <link rel="canoncial" href={process.env.FRONT_END_URL} />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, user-scalable=no, maximum-scale=1, shrink-to-fit=no"
                />
                <meta
                    name="description"
                    content="ViTeach verwendet neueste Ansätze der künstlichen Intelligenz um optimale Darbietung von Inhalten und damit maximalen Lernerfolg zu garantieren."
                />
                <meta httpEquiv="Content-Language" content="de" />
                <meta property="og:title" content="So macht Mathe Spaß - Mit künstlicher Intelligenz zum Lernerfolg" />
                <meta
                    property="og:description"
                    content="ViTeach verwendet neueste Ansätze der künstlichen Intelligenz um optimale Darbietung von Inhalten und damit maximalen Lernerfolg zu garantieren."
                />
                <meta
                    property="og:image"
                    content="https://cdn.pixabay.com/photo/2015/11/05/08/21/geometry-1023846_960_720.jpg"
                />
                <meta property="og:url" content={process.env.FRONT_END_URL} />
            </Head>
            <Landing />
        </>
    );
};

export default LandingPage