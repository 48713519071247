import { useState, useContext } from 'react'
import { Col } from 'react-bootstrap'

import UserProvider from '@/Contexts/UserProvider'

import Signup from '@/Components/SignupLoginModal'
// import ReferralBanner from './ReferralBanner';

import {
    TopWaves,
    TopSpace,
    MathSymbols,
    NonScreenContainer,
    ScreenContainer,
    VideoContainer,
    MainClaimBox,
    LoginButtonContainer,
    LoginButton,
    LoginButtonSub,
    ClaimHeader,
    SubClaim,
    ResponsiveContainer,
    HoverRow,
    HoverHeader,
    ResponsiveIcon,
} from './styles'

import Testimonials from './Testimonials'

const claims = [
    {
        title: 'Personalisierte und adaptive Lernmethodik',
        text: `Schüler in derselben Klasse besitzen in der Regel
              unterschiedliches Vorwissen, sind unterschiedlich begabt und
              entsprechen unterschiedlichen Lerntypen. All diese Unterschiede
              führen dazu, dass jeder Schüler anders lernt. Zu Beginn wird anhand
              eines kurzen Fragebogens ermittelt, wie ein Schüler am
              effizientesten lernt. Anschließend wird fortlaufend auf Basis
              erzeugter Datenpunkte für jeden Schüler die verwendete Lernmethodik
              adaptiert. Die entsprechenden Lerninhalte können nun personalisiert
              optimal dargestellt werden.`,
        img: '/assets/icons/landingpage/Personalisierte_und_adaptive_lernmethodik.svg',
    },
    {
        title: 'Interaktive Darstellung aller Inhalte',
        text: `Anhand realistischer Beispiele können Schüler interaktiv mit
              Lernkomponenten agieren und selbständig relevante Parameter (z.B.
              die Steigung bei Funktionen) ändern. Die Schüler erhalten direktes
              Feedback und können beobachten, welche Auswirkungen ihre Änderungen
              haben. Auch bei Übungsaufgaben werden Lösungswege Schritt für
              Schritt dargestellt und bei jedem Lösungsschritt können die
              Eingangswerte geändert werden.`,
        img: '/assets/icons/landingpage/Interaktive_darstellung_aller_inhalte.svg',
        imgRight: false,
    },
    {
        title: 'Erstklassiges und umfangreiches Lehrangebot',
        text: `Unser gesamtes Lehrangebot wird von ausgewiesenen Experten mit
              jahrzehntelanger fachlicher und pädagogischer Erfahrung optimal
              aufgearbeitet, verifiziert, und regelmäßig überarbeitet. Ist ein
              gewünschtes Thema im Bereich Schulmathematik noch nicht auf unserer
              Seite, stellen wir es dem Schüler innerhalb einer Woche auf ViTeach
              zur Verfügung.`,
        img: '/assets/icons/landingpage/Erstklassiges_und_umfangreiches_lehrangebot.svg',
    },
    {
        title: 'Kostenloser Service',
        text: `Teure Nachhilfe gehört der Vergangenheit an. Das Angebot von ViTeach
              steht kostenlos zur Verfügung. Schüler, die von Beginn an dabei
              sind, erhalten kostenlosen Zugriff auf alle Lerninhalte, und damit
              auf Tausende Erklärungen und Übungsaufgaben zu verschiedensten
              Themen im Bereich der Schulmathematik.`,
        img: '/assets/icons/landingpage/Kostenloses_angebot.svg',
        imgRight: false,
    },
    {
        title: 'KI-basierte Lernmethoden',
        text: `Mit künstlicher Intelligenz wird der maximale Lernerfolg,
              personalisiert für jeden einzelnen Schüler, erreicht. Dank
              modernster Technologie und eigens für ViTeach entwickelten
              Algorithmen, bestimmen wir optimale, personalisierte Lernstrategien
              für bessere Schulnoten. Als Bestandteil der Strategie wird
              notwendiges Vorwissen identifiziert und die gegebenenfalls
              bestehenden Wissenslücken nachhaltig geschlossen.`,
        img: '/assets/icons/landingpage/KI-basierte_lernmethoden.svg',
    },
    {
        title: 'Spielerisch zu coolen Geschenken',
        text: `Für richtige Antworten sammeln die Schüler Punkte, und sie können
              sich innerhalb ihrer Klassenstufe untereinander vergleichen. Je
              besser ein Schüler ist, desto höher ist seine Chance auf
              interessante Preise. Dies führt nachweislich zu höherer
              Unterhaltsamkeit und damit zu längeren Nutzungsdauern.`,
        img: '/assets/icons/landingpage/Spielerisch_zu_coolen_geschenken.svg',
        imgRight: false,
    },
    {
        title: 'Individuelle Betreuung durch Fachexperten',
        text: `Der individuelle Lernerfolg steht bei uns an erster Stelle. Unsere
              hochqualifizierten und verständnisvollen Fachkräfte sind immer für
              die Schüler verfügbar. Versteht ein Schüler ein Thema trotz unserer
              bereitgestellten Inhalte nicht, unterstützen unsere Experten
              individuell und helfen bei den jeweiligen Fragen.`,
        img: '/assets/icons/landingpage/Individuelle_betreuung_durch_fachexperten.svg',
    },
    {
        title: 'Millionen Übungsaufgaben mit Lösungswegen',
        text: `Nie wieder nach geeigneten Übungsaufgaben für die nächste
              Klassenarbeit suchen. Bei ViTeach erhalten die Schüler zu jedem
              Thema nahezu unendlich viele Übungsaufgaben in verschiedensten
              Schwierigkeitsgraden, die mittels proprietärer Modelle generiert
              werden.`,
        img: '/assets/icons/landingpage/Millionen_uebungsaufgaben_mit_loesungswegen.svg',
        imgRight: false,
    },
    {
        title: 'Effizientes Lernen für bessere Noten',
        text: `Bessere Schulnoten in Mathe? Mit ViTeach ein Kinderspiel. Dank
              unserer adaptiven Lernmethodik und unserer auf modernsten
              Technologien entwickelten künstlichen Intelligenz bieten wir die
              beste Grundlage, um mit Spaß zu besseren Noten zu kommen.`,
        img: '/assets/icons/landingpage/Effizientes_lernen_fuer_bessere_noten.svg',
    },
    {
        title: 'Detaillierte Berücksichtigung aktueller Lehrpläne',
        text: `Wir bereiten jeden Schüler optimal auf seine anstehende
              Klassenarbeit vor und erstellen persönliche Lernpfade. Mit wenigen
              Informationen (wie z.B. Klasse, Bundesland, und Thema der
              Klassenarbeit) berücksichtigen wir dabei die aktuellen Lehrpläne für
              jedes Bundesland, und schaffen eine optimale Lernumgebung und
              -vorbereitung.`,
        img: '/assets/icons/landingpage/Detaillierte_beruecksichtigung_aktueller_lehrplaene.svg',
        imgRight: false,
    },
    {
        title: 'Perfekte Unterstützung für Lehrkräfte',
        text: `Der digitale Unterricht nimmt in allen Schulen Einzug. Lehrer (aber
              auch Dozenten, Tutoren und Nachhilfelehrer) können ihren Schülern
              maßgeschneiderte Konzepte über ViTeach zur Verfügung stellen, um
              diese im Unterricht zu nutzen, oder Übungsaufgaben innerhalb einer
              Klasse zu verteilen. Mittels personalisierter Dashboards können die
              Lehrer direkt auswerten, ob jeder Schüler in ihrer Klasse das Thema
              verstanden hat.`,
        img: '/assets/icons/landingpage/Perfekte_unterstützung_fuer_lehrkraefte.svg',
    },
    {
        title: 'Optimale Darstellung auf allen Geräten',
        text: `Auf dem Weg zur Klassenarbeit nochmal schnell ein Thema verstehen
              oder eine letzte Übungsaufgabe zur Sicherheit rechnen? Egal wo sich
              Schüler befinden (ob in der Schule, zuhause, oder unterwegs),
              ViTeach steht auf alle Geräten optimal zur Verfügung - auch ohne
              Installation einer Mobile App.`,
        img: '/assets/icons/landingpage/Optimale_darstellung_auf_allen_geraeten.svg',
        imgRight: false,
    },
    {
        title: 'Bildungsgerechtigkeit für alle',
        text: `Bildungsgerechtigkeit und Chancengleichheit sind uns ein extrem
              wichtiges Anliegen, und wir wollen uns tatkräftig hierfür einsetzen.
              Sobald Teile unseres Angebots kostenpflichtig werden, stellen wir
              ein reserviertes Kontingent für Schüler mit schwächeren
              sozio-ökonomischen Hintergründen zur Verfügung, um diesen weiterhin
              einen kostenlosen Zugang zu erstklassiger Bildung zu ermöglichen.`,
        img: '/assets/icons/landingpage/Bildungsgerechtigkeit_fuer_alle.svg',
    },
]

const Section = ({ title, text, img, imgRight = true }) => {
    return (
        <HoverRow>
            {!imgRight && (
                <Col xs={12} md={6} className={'d-none d-md-flex justify-content-center'}>
                    <div className={'d-flex align-items-center'}>
                        <ResponsiveIcon src={img} />
                    </div>
                </Col>
            )}{' '}
            <Col xs={12} md={6} className={'d-flex flex-column justify-content-center'}>
                <HoverHeader>{title}</HoverHeader>
                <p>{text}</p>
            </Col>
            {imgRight && (
                <Col xs={12} md={6} className={'d-flex justify-content-center'}>
                    <div className={'d-flex align-items-center'}>
                        <ResponsiveIcon src={img} />
                    </div>
                </Col>
            )}
            {!imgRight && (
                <Col xs={12} md={6} className={'d-flex d-md-none justify-content-center'}>
                    <div className={'d-flex align-items-center'}>
                        <ResponsiveIcon src={img} />
                    </div>
                </Col>
            )}
        </HoverRow>
    )
}

const SVG = ({ id, top, bottom, height, path, color, viewBox, opacity }) => {
    return (
        <svg
            id={id}
            xmlns="http://www.w3.org/2000/svg"
            style={{
                background: 'white',
                zIndex: -100,
                width: '100%',
                position: 'absolute',
                top,
                bottom,
                height,
            }}
            viewBox={viewBox}
            preserveAspectRatio="none"
        >
            <g transform="">
                <path d={path} fill={color} opacity={opacity} />
            </g>
        </svg>
    )
}

const Wave = ({
    start = '0px',
    color = '#E28215',
    pathUpper = 'M 0 856 L 0 100 Q 197 476 377 401 T 730 496 T 1059 583 T 1479 525 T 1792 674 L 1792 856 Z',
    pathLower = 'M 0 0 L 0 283 Q 197 276 377 201 T 730 396 T 1059 483 T 1479 525 T 1792 674 L 1793 0 Z',
    yViewBox = 0,
    opacity = '.2',
    bottom,
}) => {
    const firstWave = 400
    const centerHeight = 500

    return (
        <>
            {!bottom ? (
                <div style={{ position: 'relative', top: start }}>
                    <SVG
                        top="0px"
                        height={`${firstWave}px`}
                        path={pathUpper}
                        color={color}
                        viewBox={`0 ${yViewBox} 1507 856`}
                        opacity={opacity}
                    />
                    <div
                        style={{
                            backgroundColor: color,
                            position: 'absolute',
                            top: `${firstWave}px`,
                            height: `${centerHeight}px`,
                            width: '100%',
                            zIndex: -10,
                            opacity,
                        }}
                    />
                    <SVG
                        top={`${firstWave + centerHeight}px`}
                        height={`${firstWave}px`}
                        path={pathLower}
                        color={color}
                        viewBox="0 0 1795 1020"
                        opacity={opacity}
                    />
                </div>
            ) : null}
        </>
    )
}

const Landing = () => {
    const [show, setShow] = useState(false)

    const {
        user: { data: { _id: userId } = {} },
    } = useContext(UserProvider.context)

    return (
        <div style={{ width: '100%', overflowX: 'hidden' }}>
            {/* CURRENTLY NOT LIVE */}
            {/* <div style={{ width: '100%', backgroundColor: 'white' }}>
                <ReferralBanner />
            </div> */}
            <div
                style={{
                    position: 'relative',
                }}
            >
                <MathSymbols position="top" />

                {/* <TopWaves /> */}
                <TopSpace />

                <NonScreenContainer>
                    <MainClaimBox>
                        <ClaimHeader>
                            Vom MatheHass
                            <br /> zum MatheAss
                        </ClaimHeader>
                        <SubClaim>
                            Du bestimmst, was, wie und wie schnell Du lernen willst. Interaktiv. Individuell. Kostenlos.
                        </SubClaim>
                        <LoginButtonContainer>
                            {userId ? (
                                <LoginButton
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={() => {
                                        window.location = `${process.env.FRONT_END_URL}/flow/overview`
                                    }}
                                >
                                    Persönlicher Bereich
                                </LoginButton>
                            ) : (
                                <LoginButton onMouseDown={(e) => e.preventDefault()} onClick={() => setShow(true)}>
                                    LEGE JETZT LOS!
                                </LoginButton>
                            )}
                            <LoginButtonSub>100% kostenlos</LoginButtonSub>
                        </LoginButtonContainer>
                    </MainClaimBox>
                </NonScreenContainer>
                <ScreenContainer>
                    <img
                        src={`/assets/backgrounds/macbook.png`}
                        style={{ width: '100%', position: 'absolute', left: '0', zIndex: -100 }}
                    />
                    <VideoContainer>
                        <video autoPlay playsInline loop muted style={{ width: '100%', marginLeft: '17%' }}>
                            <source src={`/assets/videos/flow.mp4`} type="video/mp4" />
                        </video>
                    </VideoContainer>
                </ScreenContainer>

                <Wave start="400px" />
                <Wave
                    start="1700px"
                    color="#83B8F3"
                    opacity=".4"
                    pathUpper="M 0 740 L 0 674 Q 197 476 377 401 T 730 396 T 1059 483 T 1479 325 T 1792 174 L 1793 740 Z"
                    pathLower="M 0 0 L 0 674 Q 197 476 377 401 T 730 396 T 1059 483 T 1479 325 T 1792 174 L 1793 0 Z"
                    yViewBox="-173"
                />
                <Wave start="3100px" color="#83B8F3" />

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        background: 'white',
                        zIndex: -100,
                        width: '100%',
                        position: 'absolute',
                        bottom: '0px',
                        height: '400px',
                    }}
                    viewBox="-1 -567 1795 566"
                    preserveAspectRatio="none"
                    className="d-block d-md-none"
                >
                    <path
                        d="M 0 0 L 0 -66 Q 197 -264 377 -339 T 730 -344 T 1059 -257 T 1479 -415 T 1792 -566 L 1793 0 Z"
                        fill="#E28215"
                        opacity=".4"
                    />
                </svg>
                <ResponsiveContainer>
                    {claims.slice(0, 5).map((claim) => {
                        return <Section {...claim} key={claim.title} />
                    })}
                    <div
                        style={{
                            width: '80%',
                            border: 'solid 3px #5B6B79',
                            borderRadius: '5px',
                            height: '2px',
                            margin: '40px 0px',
                            marginLeft: '10%',
                        }}
                    />
                    <h1>Was andere Schüler über ViTeach sagen</h1>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            zIndex: 100,
                            paddingBottom: '25px',
                            height: '300px',
                        }}
                    >
                        <Testimonials />
                    </div>
                    <div
                        style={{
                            width: '80%',
                            border: 'solid 3px #5B6B79',
                            borderRadius: '5px',
                            height: '2px',
                            margin: '10px 0px 20px 0px',
                            marginLeft: '10%',
                        }}
                    />
                    {claims.slice(5).map((claim) => {
                        return <Section {...claim} key={claim.title} />
                    })}
                </ResponsiveContainer>
                <MathSymbols />
            </div>
            <Signup show={show} setShow={setShow} />
        </div>
    )
}

export default Landing
